.hidden-for-print {
    display: none;
  }
.hidden-for-print1{
    display: block;
}  



/* Basic styles for the checkbox container */
.custom-radio {
  position: relative;
  -webkit-appearance: none; /* Remove default browser styling */
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #007bff; /* Border color */
  border-radius: 50%; /* Circular shape */
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 0;
  margin-left: 10px;
}

/* Radio button when checked */
.custom-radio:checked {
  background-color: #007bff;
  border-color: #007bff;
  position: relative;
}

/* Custom radio dot (when selected) */
.custom-radio:checked::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white; /* The inner dot color when checked */
}

/* Hover and focus styles for better UX */
.custom-radio:hover {
  border-color: #0056b3;
}

.custom-radio:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.custom-checkbox {
  position: relative;
  -webkit-appearance: none; /* Remove default browser styling */
  -moz-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border: 2px solid #007bff; /* Border color */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  padding:10px;
  margin-left: 10px;
  top:3px;
}

/* Checkbox when checked */
.custom-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
  position: relative;
}

/* Custom checkmark appearance */
.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Hover and focus styles for better UX */
.custom-checkbox:hover {
  border-color: #0056b3;
}

.custom-checkbox:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


.form-control-label {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}